import { useContext } from 'react';
import { Context as ErrorMessageContext, ErrorContent } from './contexts/ErrorMessageContext';

export const useSetErrorMessage = () => {
  const {
    setError,
    state: { hasError },
  } = useContext(ErrorMessageContext);
  const handleSetError = (content: ErrorContent): void => {
    setError(content);
  };
  return [hasError, handleSetError] as const;
};
