import styled from '@emotion/styled';
import { fontSize75, fontSize800, space100, space125, space75 } from '@ingka/variables';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space125};
  margin: 3.75rem auto;
  max-width: 560px;
`;

export const ErrorTitle = styled.h1`
  font-size: ${fontSize800};
  margin-bottom: ${space75};
`;

export const ErrorMessage = styled.p`
  font-size: ${fontSize75};
  line-height: 22px;
  margin-bottom: ${space100};
`;

export const TextContainer = styled.div`
  max-width: 490px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
export const ErrorImage = styled.img`
  height: 270px;
  width: auto;
  margin-inline: auto;
`;
