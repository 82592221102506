import { UploadNewLocale } from 'components/admin/UploadNewLocale';
import Container from 'components/common/Container';
import { useContext, useEffect } from 'react';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { useHistory } from 'react-router-dom';
import { useSetErrorMessage } from '../../hooks/useSetErrorMessage';

export const UploadPage = () => {
  const { isAdmin } = useContext(LoginContext);
  const { goBack } = useHistory();
  const [_, handleSetErrorMessage] = useSetErrorMessage();

  useEffect(() => {
    if (!isAdmin()) {
      handleSetErrorMessage({
        message: 'Let’s get you back on track…',
        title: '401 authorisation required',
        action: () => goBack(),
      });
    }
  }, [isAdmin, goBack, handleSetErrorMessage]);

  return (
    <Container page="upload">
      <UploadNewLocale />
    </Container>
  );
};
