import React, { useEffect } from 'react';
import { Error } from '../components/Error';
import { ContainerWrapper } from '../components/common/Container';
import { useSetErrorMessage } from '../hooks/useSetErrorMessage';

export const NotFoundPage: React.FC = () => {
  const [hasError, handleSetErrorMessage] = useSetErrorMessage();
  useEffect(() => {
    handleSetErrorMessage({
      title: '404 Page not found',
      buttonText: 'Go Back to Home',
      message: (
        <span>
          The page you are looking for might have been removed
          <br /> or is temporarily unavailable.
        </span>
      ),
    });
  }, []);
  return <ContainerWrapper>{hasError && <Error />}</ContainerWrapper>;
};
