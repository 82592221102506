import { FC, useContext, useEffect } from 'react';
import Button from '@ingka/button';
import { Context as ErrorMessageContext } from '../../hooks/contexts/ErrorMessageContext';
import * as Styled from './styles';

export const Error: FC = () => {
  const {
    state: { message, title, buttonText, action, hasError },
    setClearError,
  } = useContext(ErrorMessageContext);

  const defaultErrorContent = {
    title: 'Oops! Something went wrong',
    message: 'Please try again later',
    buttonText: 'Go Back',
    onClick: () => {
      window.location.href = '/';
    },
  };
  const onClickRestError = (): void => {
    setClearError();
    action ? action() : defaultErrorContent.onClick();
  };
  useEffect(() => {
    if (hasError)
      return () => {
        setClearError();
      };
  }, [hasError, setClearError]);

  if (!hasError) return null;
  return (
    <Styled.StyledContainer data-testid={'error-container'}>
      <Styled.ErrorImage src={`/error.svg`} alt={'error occurred'} />
      <Styled.TextContainer>
        <Styled.ErrorTitle>{title ?? defaultErrorContent.title}</Styled.ErrorTitle>
        <Styled.ErrorMessage>{message ?? defaultErrorContent.message}</Styled.ErrorMessage>
        <Button text={buttonText ?? defaultErrorContent.buttonText} onClick={onClickRestError} size={'small'} />
      </Styled.TextContainer>
    </Styled.StyledContainer>
  );
};
