import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import LocaleHeader from 'components/common/LocaleHeader';
import { FlexContainer } from 'components/FlexContainer';
import { EditSheet } from 'features/MetaData/EditSheet';
import { Provider as EditorProvider } from 'hooks/contexts/EditorContext';
import { Context as MetadataContext } from 'hooks/contexts/MetaDataContext';
import { useLoader } from 'hooks/useLoader';
import { Column as MetaDataTableColumns } from 'types';
import { CurrentVersionQuery, FilterInput, MetaDataEntryInput, useEditItemMutation } from '__generated__/types';
import { MetadataItem } from './MetadataItem';
import { MetadataStats } from './MetadataStats';
import { MetaDataStrippedTable } from './MetaDataStrippedTable';
import * as Styled from './styles';
import { toMetaData } from '../../../opt-util/toGenericMetadata';
import { useHistory } from 'react-router-dom';
import { useSetErrorMessage } from '../../../hooks/useSetErrorMessage';

interface MetaDataEntryTableDataProps {
  isEditable: boolean;
  data: NonNullable<CurrentVersionQuery['currentVersion']>;
  displayListOrSquareView: number;
  refetchCallback: () => void;
  filter: FilterInput;
  columns: MetaDataTableColumns[];
}

export const MetaDataEntryTableData: React.FC<MetaDataEntryTableDataProps> = ({
  isEditable,
  data,
  refetchCallback,
  filter,
  displayListOrSquareView,
  columns,
}) => {
  const {
    state: { activeItemId: itemId },
  } = useContext(MetadataContext);
  const [_, handleSetErrorMessage] = useSetErrorMessage();

  const entries = data.entries.entries;
  const versionDetails = {
    type: data.type,
    urlSlugSyncEnabled: data.urlSlugSyncEnabled,
    versionId: data._id,
    market: { country: data.country, language: data.language },
  };
  const editorInitialState = useMemo(() => {
    const currentlyEditedEntry = entries.find((entry) => entry.id === itemId);
    if (!currentlyEditedEntry) return;
    const descriptionIsSynced = currentlyEditedEntry.description === currentlyEditedEntry.ogDescription;
    const isOptimized = !!currentlyEditedEntry.isOptimized;
    const needsReview = !!currentlyEditedEntry.needsReview;
    return {
      formData: { ...currentlyEditedEntry, descriptionIsSynced, isOptimized, needsReview },
      hasErrors: false,
      errors: {},
      versionDetails,
      isOpen: true,
      itemId,
    };
  }, [entries, itemId]);
  const { goBack } = useHistory();
  const [, setLoading] = useLoader();
  const [editItem] = useEditItemMutation();
  const versionId = data._id;
  const editItemCallback = useCallback(
    async (entry: MetaDataEntryInput) => {
      const item = toMetaData(entry);
      await editItem({
        variables: {
          versionId,
          entry: { ...item, pageTitle: item.pageTitle || '' },
          filter,
        },
      }).then(() => {
        setLoading(false);
        return refetchCallback();
      });
    },
    [versionId, refetchCallback]
  );

  useEffect(() => {
    if (entries.length <= 0)
      handleSetErrorMessage({
        message: 'We cannot find the item you are searching for',
        title: 'No data',
        action: () => goBack(),
      });
  }, [entries, goBack, handleSetErrorMessage]);

  return (
    <FlexContainer direction="column">
      <Styled.MetadataItemWrapper>
        <LocaleHeader country={data.country} language={data.language} />
        <MetadataStats totalItems={data.size} optimizedItems={data.optimizedItems} />
        {displayListOrSquareView === 1 &&
          entries.length > 0 &&
          entries.map((entry) => {
            return (
              <MetadataItem
                key={entry.id}
                data={entry}
                isEditable={isEditable}
                type={data.type}
                versionId={data._id}
                author={entry.author ?? 'unknown'}
                diffVersionId={entry.diffVersionId}
                filter={filter}
                onDelete={refetchCallback}
              />
            );
          })}
      </Styled.MetadataItemWrapper>

      {displayListOrSquareView === 0 && entries.length > 0 && (
        <MetaDataStrippedTable
          entries={entries}
          type={data.type}
          versionId={versionId}
          filter={filter}
          columns={columns}
          onDelete={refetchCallback}
        />
      )}
      {editorInitialState && (
        <EditorProvider injectedState={editorInitialState}>
          <EditSheet editItem={editItemCallback} />
        </EditorProvider>
      )}
    </FlexContainer>
  );
};
